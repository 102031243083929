import React from "react";

const RocketIcon = ({
  fill = "#bc1d21",
  fillAccent = "#fff",
  className,
  width,
  height,
}: {
  fill?: string;
  fillAccent?: string;
  width?: string;
  height?: string;
  className?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 96 96"
    width={width ?? "96"}
    height={height ?? "96"}
    className={className}
  >
    <path
      fill={fillAccent}
      d="M96,4.3c0-1.2-.7-2.3-1.3-3-.7-.7-1.7-1.3-2.9-1.3-5.2.2-11.3,1.1-17.5,2.9h0c-13,3.6-27,10.7-36.4,21.8-1.7,2-3.2,3.9-4.5,5.6l-14.9-.2c-.7,0-1.4.3-2,.8L.8,46.4c-1.7,1.7-.5,4.6,1.9,4.7l19.8.2c.4,0,.8,0,1.1-.2.3.7.7,1.3,1.2,1.8l.8.8-22.8,10.9c-3,1.4-2.2,5.9,1.2,6.2l17,1.3c.7,0,1.4.3,1.9.8l.3.3c.5.5.7,1.1.8,1.9l1.3,17c.3,3.3,4.8,4.2,6.2,1.2l10.9-22.8.8.8c.5.5,1.2.9,1.8,1.2-.1.3-.2.7-.2,1.1l.2,19.8c0,2.4,3,3.6,4.7,1.9l15.5-15.6c.5-.5.8-1.2.8-2l-.2-14.9c1.8-1.3,3.6-2.8,5.6-4.5,11.1-9.4,18.3-23.3,21.9-36.4h0c1.7-6.3,2.7-12.3,2.9-17.6Z"
    />
    <circle
      fill={fill}
      cx="66.8"
      cy="29.2"
      r="10.1"
      transform="translate(-5 42.1) rotate(-33.8)"
    />
  </svg>
);

export default RocketIcon;
