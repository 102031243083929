import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import {
  setHuddleDay,
  setHuddleId,
  setHuddleTime,
  startOrJoinHuddle,
  setTopicArticle,
} from "../../reducers/huddleSlice";
import { fetchUserHuddles } from "../../reducers/userHuddlesSlice";
import { getCurrentDateTime } from "@/app/_utils/helper";
import { Button } from "../buttons/button";
import { Input } from "../forms/input";
import LocationSearchInput from "../search/LocationSearchInput";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/app/_components/ui/dialog";
import HuddleTopicPicker from "./HuddleTopicPicker";
import { AppDispatch } from "../../reducers/store";
import RocketIcon from "../graphics/RocketIcon";
import { getFormattedDate } from "@/app/_utils/formatter";
import ResetVegxHuddle from "./ResetVegxHuddle";
import useVegxHuddle from "./useVegxHuddle";
import { useRouter } from "next/navigation";

const StartHuddle = ({
  open,
  setOpen,
  buttonLabel = "Start a Huddle",
  buttonIcon,
  dialogTitle = "New Huddle",
  children,
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  buttonLabel?: string;
  buttonIcon?: React.ReactNode;
  dialogTitle?: string;
  children?: React.ReactNode;
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [changeDetails, setChangeDetails] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );
  const dailyArticleId = useSelector(
    (state: RootState) => state.dailyArticle.article.id,
  );
  const { selectedLocation } = useSelector(
    (state: RootState) => state.locationDashboard,
  );
  const articles = useSelector((state: RootState) => state.articles);
  const dailyArticle = articles.articles[dailyArticleId];
  const topicArticle = useSelector(
    (state: RootState) => state.huddleGroups.topicArticle,
  );
  const {
    vegxUserHuddle,
    loading,
    articleMode,
    setArticleMode,
    vegxHuddleArticleId,
    vegxHuddleArticleTitle,
    hasHuddledOnVegx,
  } = useVegxHuddle(open, accessToken, dailyArticle);

  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (open) {
      const { currentDateString, currentTimeString } = getCurrentDateTime();
      setSelectedDate(currentDateString);
      setSelectedTime(currentTimeString);
    }
  }, [open]);

  const onSaveChanges = async () => {
    if (!selectedLocation) {
      setErrorMessage("Please select a location");
      return;
    }

    if (!accessToken) {
      setErrorMessage("You must be logged in to start a huddle.");
      return;
    }

    setIsSaving(true);
    const dateTime = new Date(`${selectedDate}T${selectedTime}`);
    const huddleTime = `${selectedDate}T${selectedTime}`;
    const huddleDate = new Date(huddleTime);

    const formattedHuddleDay = `${dateTime.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })} @ ${
      dateTime.getHours() % 12 || 12
    }:${dateTime.getMinutes().toString().padStart(2, "0")}${
      dateTime.getHours() >= 12 ? "pm" : "am"
    }`;

    try {
      dispatch(setHuddleDay(formattedHuddleDay));
      dispatch(setHuddleId(null));
      dispatch(setHuddleTime(huddleTime));

      const body = {
        huddleTime: huddleDate.toISOString().slice(0, -1),
        location: selectedLocation.name,
      };

      const readingListId = articleMode === "vegx" ? "vegx" : undefined;

      await dispatch(
        startOrJoinHuddle({
          articleId: topicArticle.id,
          body,
          accessToken,
          type: "start",
          router,
          readingListId,
        }),
      ).unwrap();

      setOpen(false);
      dispatch(fetchUserHuddles(accessToken));
    } catch (error) {
      setErrorMessage(
        error instanceof Error
          ? error.message
          : "An error occurred while starting the huddle.",
      );
    } finally {
      setIsSaving(false);
    }
  };

  if (!accessToken) return null;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children || (
          <Button
            id="startHuddle"
            className="px-6 py-2 h-[45px] w-[250px] md:w-[124px]"
            disabled={isSaving}
            onClick={() => setOpen(true)}
          >
            {buttonIcon || <RocketIcon width="32" height="32" />} {buttonLabel}
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <div
          id="startHuddleDialog"
          className="flex flex-col gap-4 items-center justify-center px-6 py-10 sm:p-10 max-w-[540px] text-center"
        >
          <div className="w-[45px] h-[45px] flex justify-center items-center rounded-full bg-red-600">
            <RocketIcon
              width="32"
              height="32"
              className="relative sm:scale-[.875] top-px right-px"
            />
          </div>
          <div className="text-lg -mt-4">{dialogTitle}</div>

          {errorMessage && (
            <div className="w-full text-red-600 text-sm">{errorMessage}</div>
          )}

          {changeDetails || !selectedLocation ? (
            <>
              <div className="w-full text-left">
                <label className="block text-neutral-700 text-sm mb-1">
                  <span className="text-red-600">*</span> Location:
                </label>
                <LocationSearchInput
                  additionalFilter={(item) => !item.name.includes("VQ -")}
                />
              </div>
              <div className="w-full text-left">
                <label className="block text-neutral-700 text-sm mb-1">
                  Time of Huddle:
                </label>
                <div className="grid grid-cols-2 gap-[7px]">
                  <Input
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                  <Input
                    type="time"
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                  />
                </div>
              </div>
            </>
          ) : (
            <div>
              <div className="text-xl font-bold">{selectedLocation.name}</div>
              <div>{getFormattedDate(new Date())}</div>
              <button
                onClick={() => setChangeDetails(true)}
                className="underline text-sm text-blue-link"
              >
                Change
              </button>
            </div>
          )}

          <HuddleTopicPicker
            {...{
              articleMode,
              setArticleMode,
              loading,
              dailyArticle,
              topicArticle,
              vegxHuddleArticleId,
              vegxHuddleArticleTitle,
              hasHuddledOnVegx,
              setTopicArticle,
              dispatch,
            }}
          />

          {vegxUserHuddle && <ResetVegxHuddle huddle={vegxUserHuddle} />}

          <div className="flex w-full justify-end mt-4 space-x-4">
            <Button onClick={() => setOpen(false)} variant="outline">
              Cancel
            </Button>
            <Button
              id="saveHuddle"
              variant="primary"
              onClick={onSaveChanges}
              disabled={isSaving}
            >
              Save Huddle
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StartHuddle;
